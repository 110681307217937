// JavaScript Document
/**
 * Sign Up
 */
jQuery(document).ready(function($) {
	
	$('#sign-in-bn').click(function( event ){		
		event.preventDefault();
		$('#sign-in-bn').prop('disabled', true );
		$('#error').html('<strong><span class="green">Signing in. Please wait...</span></strong>');
		$('form[name="sign_in_form"]').submit();
	});

}); 